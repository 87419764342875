import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AddressesUpdate.module.scss';
import { routeAddressModel } from '../../../models/routeAddress';
import { AddressService } from '../../../services/address.service';
import { useNavigate, useParams } from 'react-router-dom';
import { validators } from '../../../utils/validators';
import { Col, Form, Row } from 'react-bootstrap';
import SuccessModal from '../../ui/SuccessModal/SuccessModal';
import ErrorModal from '../../ui/ErrorModal/ErrorModal';
import { AuthContext } from '../../../providers/AuthProvider';
import InputMask from "react-input-mask";
import { UserService } from '../../../services/user.service';

const AddressesUpdate = () => { 
  const [data, setData] = useState(routeAddressModel);
  const [usersList, setUsersList] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const uid = useParams().uid;
  const addressService = new AddressService(user);
  const formValidators = new validators(user);
  const [validated, setValidated] = useState(false);
  const [ErrorModalMessage, setErrorModalMessage] = useState('');
  const [SuccessModalMessage, setSuccessModalMessage] = useState('');

  useEffect(() => {
    if(uid) {
      addressService.getAddress(uid).then(result => {
        setData(result);
        if(user.userInfo.isAdmin) {
          const userService = new UserService(user);
          userService.getAllUsers().then((result) => {
            const users = result.filter((clientUser) => {
              return clientUser.userType === 'Cliente';
            })
            users.unshift({name: 'Público', uid: 'publico'})
            setUsersList(users);
          })
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if((!errors || Object.keys(errors).length === 0) && validated){
      sendToFirestore();
    };
  }, [errors]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const newData = { ...data, [e.target.name]: e.target.value }
    setData(newData);
  }
  const handleOwner = (e) => {
    const owner = usersList.find(selectedUser => selectedUser.uid === e.target.value);
    const newData = { ...data, owner:owner.uid, ownerName: owner.name};
    setData(newData);
  }

  const handleSubmit = (event) => {
    setErrors({});
    event.preventDefault();
    setErrors(formValidators.validateAddressForm(data));
    setValidated(true);
  }

  const sendToFirestore = () => {
    addressService.updateAddress(data, uid).then((result) => {
      setSuccessModalMessage('Endereço atualizado com sucesso!');
    }).catch(error => {
      setErrorModalMessage('Não foi possível atualizar o endereço no momento.');
    })
  }

  const checkCep = (e) => {
    const normalizedCep = e.target.value.replace(/\D/g, '');
    if(normalizedCep.length < 8) return
    addressService.getAddressByCEP(normalizedCep).then((address) => {
      setData({
        ...data,
        street: address.logradouro,
        district: address.bairro,
        city: address.localidade,
        state: address.uf
      })
    }).catch((e) => {
      
    })
  }

  const onChange = (newData) => {
    setData(newData)
  }

  const onModalClose = () => {
    setSuccessModalMessage('');
    setErrorModalMessage('');
    navigate('/address/' + uid)
  }

  return (
    <Form noValidate onSubmit={handleSubmit} className={styles.AddressesUpdate}>
      {user.userInfo.isAdmin &&
        <div className={styles.sectionContainer} >
          <h6>Dados do Usuário</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="owner">
              <Form.Label>Dono do endereço <strong className='text-danger'>*</strong></Form.Label>
              <Form.Select
                name='owner'
                aria-label="Selecione um usuário"
                onChange={handleOwner}>
                  {usersList && usersList.map(owner => <option value={owner.uid} selected={data.owner === owner.uid}>{owner.name}</option>)}
              </Form.Select>
            </Form.Group>
          </Row>
        </div>
      }
      <div className={styles.sectionContainer}>
        <h6>Dados do Endereço</h6>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="addressName">
            <Form.Label>Nome do endereço <strong className='text-danger'>*</strong></Form.Label>
            <Form.Control
              required
              isInvalid={!data.name}
              name='name'
              type="text"
              placeholder="Nome do endereço"
              value={data.name}
              onChange={handleChange}
            />
            {errors?.name && <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="streetInput">
            <Form.Label>Rua <strong className='text-danger'>*</strong></Form.Label>
            <Form.Control
              required
              isInvalid={!data.street}
              name='street'
              type="text"
              placeholder="Rua"
              value={data.street}
              onChange={handleChange}
            />
            {errors?.street && <Form.Control.Feedback type='invalid'>{errors?.street}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="numberInput">
            <Form.Label>Número <strong className='text-danger'>*</strong></Form.Label>
            <Form.Control
              required
              name='number'
              type="text"
              isInvalid={!data.number}
              placeholder="Número"
              value={data.number}
              onChange={handleChange}
            />
            {errors?.number && <Form.Control.Feedback type='invalid'>{errors?.number}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="districtInput">
            <Form.Label>Bairro <strong className='text-danger'>*</strong></Form.Label>
            <Form.Control
              required
              isInvalid={!data.district}
              name='district'
              type="text"
              placeholder="Bairro"
              value={data.district}
              onChange={handleChange}
            />
            {errors?.district && <Form.Control.Feedback type='invalid'>{errors?.district}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="cityInput">
            <Form.Label>Cidade <strong className='text-danger'>*</strong></Form.Label>
            <Form.Control
              required
              isInvalid={!data.city}
              name='city'
              type="text"
              placeholder="Cidade"
              value={data.city}
              onChange={handleChange}
            />
            {errors?.city && <Form.Control.Feedback type='invalid'>{errors?.city}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="stateInput">
            <Form.Label>Estado <strong className='text-danger'>*</strong></Form.Label>
            <Form.Control
              required
              isInvalid={!data.state}
              name='state'
              type="text"
              placeholder="Estado"
              value={data.state}
              onChange={handleChange}
            />
            {errors?.state && <Form.Control.Feedback type='invalid'>{errors?.state}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="cepInput">
            <Form.Label>CEP <strong className='text-danger'>*</strong></Form.Label>
            <InputMask placeholder='CEP' name='cep' className={`form-control ${Boolean(errors?.phone) && 'is-invalid'}` } value={data.cep} onChange={handleChange} onBlur={checkCep} mask="99999-999"></InputMask>
            {errors?.cep && <Form.Control.Feedback type='invalid'>{errors?.cep}</Form.Control.Feedback>}  
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="complementInput">
            <Form.Label>Ponto de referência</Form.Label>
            <Form.Control
              name='complement'
              type="text"
              placeholder="Ponto de referência"
              value={data.complement}
              onChange={handleChange}
            />
            {errors?.complement && <Form.Control.Feedback type='invalid'>{errors?.complement}</Form.Control.Feedback>}
          </Form.Group>
        </Row>
      </div>
      <div className={styles.InputContainer}>
        <div className={styles.Buttons}>
          <button className={styles.PrimaryButton} type='submit'>Confirmar</button>
          <button className={styles.SecondaryButton} onClick={() => window.history.back()}>Voltar</button>
        </div>
      </div>
      <ErrorModal show={Boolean(ErrorModalMessage)} message={ErrorModalMessage} onClose={onModalClose}></ErrorModal>
      <SuccessModal show={Boolean(SuccessModalMessage)} message={SuccessModalMessage} onClose={onModalClose}></SuccessModal>
    </Form>
  )
}
AddressesUpdate.propTypes = {};

AddressesUpdate.defaultProps = {};

export default AddressesUpdate;
