
export function WhatsappService () {

    const _getHeader = () => {
        return {
            'apikey': process.env.REACT_APP_WA_API_KEY,
            'content-Type': 'application/json'
        }
    }

    const verifyNumber = (cellphone) => {
        cellphone = cellphone.replace(/\D/g,'');
        const body = JSON.stringify({
            numbers: [`55${cellphone}`]
        })
        return new Promise(async (resolve, reject) => {
            fetch(`${process.env.REACT_APP_WA_BASE_URL}/chat/whatsappNumbers/${process.env.REACT_APP_WA_INSTANCE}`, {method: 'POST', body, headers: _getHeader()}).then(res => {
                if(res.error) reject(res.json());
                resolve(res.json());
            }).catch(e => {
                reject(e);
            })
        })
    }

    const sendMessages = (number, message) => {
        const cellphone = `55${number.replace(/\D/g,'')}@s.whatsapp.net`
        const body = JSON.stringify({
            number: cellphone,
            options: {
                delay: 1200,
                presence: 'composing',
                linkPreview: false
            },
            textMessage: {
                text: message
            }
        })
        return new Promise(async (resolve, reject) => {
            fetch(`${process.env.REACT_APP_WA_BASE_URL}/message/sendText/${process.env.REACT_APP_WA_INSTANCE}`, {method: 'POST', body, headers: _getHeader()}).then(res => {
                if(res.error) reject(res.json());
                resolve(res.json());
            }).catch(e => {
                reject(e);
            })
        })
    }

    return {
        verifyNumber
    }
}