import React, { useContext, useEffect, useState } from 'react';
import styles from './create.module.scss';
import Trip from './trip/trip';
import Passengers from './passengers/passengers';
import AditionalData from './aditionalData/aditionalData';
import Resume from './resume/resume';
import { SolicitationModel } from '../../../../models/solicitation';
import { passengerModel } from '../../../../models/passenger';
import { routeAddressModel } from '../../../../models/routeAddress';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../../providers/AuthProvider';
import { SolicitationService } from '../../../../services/solicitation.service';
import ErrorModal from '../../../ui/ErrorModal/ErrorModal';
import SuccessModal from '../../../ui/SuccessModal/SuccessModal';
import { Alert } from 'react-bootstrap';
import { AddressService } from '../../../../services/address.service';
import { formatAddress } from '../../../utils/utils';
import { PassengerService } from '../../../../services/passengers.service';
import Owner from './owner/owner';
import { UserService } from '../../../../services/user.service';

const Create = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const userService = new UserService(user);
  const solicitationService = new SolicitationService(user);
  const addressService = new AddressService(user)
  const passengerService = new PassengerService(user);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ addressList, setAddressList ] = useState();
  const [ passengersList, setPassengersList ] = useState();
  const [ usersList, setUsersList] = useState();
  const [ solicitation, setSolicitation ] = useState(SolicitationModel);
  const solicitationType = useParams().solicitationType;
  const [ customOwner, setCustomOwner ] = useState();
  const [aditionalData, setAditionalData] = useState({
    owner: user.uid,
    costCenter: "",
    paymentMethod: "",
    obs: ""
  });
  const [passengersData, setPassengersData] = useState([passengerModel]);
  let [Addresses, setAddresses] = useState([{title: "Endereço de origem", address: routeAddressModel}, {title: "Endereço de destino", address: routeAddressModel}])
  const [ ErrorModalMessage, setErrorModalMessage ] = useState('');
  const [ SuccessModalMessage, setSuccessModalMessage ] = useState('');
  const [ isDisabled, setIsDisabled ] = useState(true);
  
  const hasInvalidOwner = () => {
    return !customOwner?.uid;
  }

  const hasInvalidAddresses = () => {
    let invalidAddresses = Addresses.find(data => {
      return !data.address.street || (data.title !== "Endereço de destino" && !data.address.datetime);
    })
    return Boolean(invalidAddresses);
  }

  const hasInvalidatePassengers = () => {
    let invalidPassenger = passengersData.find(data => {
      return !data.name;
    })
    return Boolean(invalidPassenger);
  }

  const hasInvalidateAditionalData = () => {
    return !aditionalData.paymentMethod;
  }

  const nextStep = () => {
    if(!user.userInfo.isAdmin &&(
      (activeStep === 0 && hasInvalidAddresses()) || 
      (activeStep === 1 && hasInvalidatePassengers()) || 
      (activeStep === 2 && hasInvalidateAditionalData()))
    ) {
      setIsDisabled(true);
    } else if(
      user.userInfo.isAdmin &&(
      (activeStep === 0 && hasInvalidOwner()) ||
      (activeStep === 1 && hasInvalidAddresses()) || 
      (activeStep === 2 && hasInvalidatePassengers()) || 
      (activeStep === 3 && hasInvalidateAditionalData()))
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      setActiveStep(activeStep + 1)
    }
  }

  const steps = [
    { title: 'Sobre a viagem', component: < Trip Addresses={Addresses} setaddresses={setAddresses} addressList={addressList}/> },
    { title: 'Sobre os passageiros', component: < Passengers Passengers={passengersData} setPassengers={setPassengersData} passengersList={passengersList} /> },
    { title: 'Informações adicionais', component: < AditionalData data={aditionalData} setData={setAditionalData}/> },
    { title: 'Resumo', component: < Resume passengers={passengersData} routeTrip={Addresses} aditionalData={aditionalData} /> },
  ]

  if(user.userInfo.isAdmin) {
    steps.unshift({title: 'Dono da solicitação', component: <Owner ownersList={usersList} onDataChange={setCustomOwner}></Owner>})
  }

  useEffect(() => {
    setSolicitation({...solicitation, type: solicitationType});
    if(user.userInfo.isAdmin) {
      userService.getAllUsers().then(result => {
        const users = result.filter(client => client.userType === 'Cliente');
        setUsersList(users);
      });
      return;
    };
    addressService.getUserAddresses().then(result => {
      setAddressList(result)
    });
    passengerService.getUserPassengers().then(result => {
      setPassengersList(result);
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    addressService.getUserAddresses(customOwner?.uid).then(result => {
      setAddressList(result)
    });
    passengerService.getUserPassengers(customOwner?.uid).then(result => {
      setPassengersList(result);
    })
  }, [customOwner]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!Addresses || !passengersData) {
      setIsDisabled(true)
    }
    setIsDisabled(false)
  }, [activeStep])

  const createSolicitation = async () => {
    const startDate = Addresses[0].address.datetime;
    let endDate;
    createAddresses().then((result) => {
      const addresses = result;
      if(addresses.length > 2) {
        endDate = addresses[Addresses.length - 2].address.datetime;
      }else {
        endDate = startDate;
      }
      createPassengers().then((passengerResult) => {
        let newSolicitation = {
          ...solicitation,
          routeAdresses: addresses,
          passengers: passengerResult,
          costCenter: aditionalData.costCenter,
          paymentMethod: aditionalData.paymentMethod,
          obs: aditionalData.obs,
          estimate: [],
          startDate: startDate,
          endDate: endDate,
          driver: '',
          shippingAddressId: null
        }
        solicitationService.createSolicitation(newSolicitation, 'CR', customOwner).then(()=> {
          setSuccessModalMessage(`${solicitation.type === 'O'? 'Orçamento': 'Serviço'} criado com sucesso! Volte em breve para receber atualizações do seu pedido`);
        }).catch(error => {
          setErrorModalMessage('Não foi possivel criar sua solicitação no momento.');
        });
      })
    });
  }

  const createAddresses = () => {
    return new Promise(async (resolve) => {
      const newAddresses = await Promise.all(
        Addresses.map(address => {
          if(address.address.uid) return address;
          if(!address.address.name) {
            address.address.name = formatAddress(address);
          }
          let newAddress = {...address.address};
          if(customOwner?.uid) {
            newAddress.owner = customOwner.uid;
          }
          return addressService.saveAddress(newAddress).then((result) => {
            address.address.uid = result;
            return address;
          })
        })
      )
      resolve(newAddresses);
    })
  }

  const createPassengers = () => {
    return new Promise(async (resolve) => {
      const newPassengers = await Promise.all(
        passengersData.map(passenger => {
          if(passenger.uid) return passenger;
          let newPassenger = {...passenger};
          if(customOwner?.uid) {
            newPassenger.owner = customOwner.uid;
          }
          return passengerService.savePassenger(newPassenger).then((result) => {
            passenger.uid = result;
            return passenger;
          })
        })
      )
      resolve(newPassengers);
    })
  }

  const onModalClose = () => {
    setSuccessModalMessage('');
    setErrorModalMessage('');
    navigate('/')
  }
  
  return (
    <div className={styles.Create}>
      <div className={styles.formContainer}>
        <div className={styles.HeaderContainer}>
          <h3>Novo Serviço</h3>
          <h4>{steps[activeStep].title}</h4>

        </div>
        {isDisabled &&
        <Alert key='alert-danger' variant='danger' >
          Preencha todos os campos obrigatórios (<strong className='text-danger'>*</strong>)
        </Alert>
        }
        {steps[activeStep].component}
        <div className={styles.buttonsGroup}>
          <div className={styles.buttonContainer}>  
            {(activeStep !== 0 && activeStep !== steps.length)
              && <button className={styles.SecondaryButton} onClick={() => setActiveStep(activeStep - 1) }>Voltar</button>
            }
          </div>
          <div className={styles.buttonContainer}>
            { activeStep !== steps.length - 1
              && <button className={styles.PrimaryButton} onClick={nextStep} >Avançar</button>
            }
            { activeStep === steps.length - 1
              && <button className={styles.PrimaryButton} onClick={ () => createSolicitation() } >Concluir</button>
            }
          </div>
        </div>
      </div>
      <ErrorModal show={Boolean(ErrorModalMessage)} message={ErrorModalMessage} onClose={onModalClose}></ErrorModal>
      <SuccessModal  show={Boolean(SuccessModalMessage)} message={SuccessModalMessage} onClose={onModalClose}></SuccessModal>
    </div>
  );
}

Create.propTypes = {};

Create.defaultProps = {};

export default Create;
